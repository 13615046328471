import clsx from 'clsx';
import { forwardRef } from 'react';
import Icon from './Icon';
import styles from './Button.module.scss';
import Spinner from './Spinner';
import type { HTMLProps, ReactNode, Ref } from 'react';

type Props = {
  /**
   * If `true`, the button is disabled
   * @default false
   */
  disabled?: boolean;
  /**
   * The style used for disabled button
   * @default 'faded'
   */
  disabledStyle?: 'grey' | 'faded';
  /**
   * If `true`, the button will take up the full width of its container
   * @default false
   */
  fullWidth?: 'mobile' | boolean;
  /**
   * If `true`, the button will use the outline (ghost) version of the variant
   * @default false
   */
  ghost?: boolean;
  /**
   * The icon that will be shown depending on the iconPosition prop
   */
  icon?: ReactNode | string;
  /**
   * The position at which the icon will be added to the button
   * @default 'after'
   */
  iconPosition?: 'before' | 'after';
  /**
   * If `true`, the button will be rendered as anchor tag
   * @default false
   */
  isLink?: boolean;
  /**
   * If `true`, a loading indicator (Spinner) is added to the button depending on the iconPosition prop
   * @default false
   */
  loading?: boolean;
  /**
   * If `true`, only the loading indicator will be shown
   * @default false
   */
  loaderOnly?: boolean;
  /**
   * A reference for the button
   */
  ref?: Ref<HTMLAnchorElement> | Ref<HTMLButtonElement>;
  /**
   * If `true`, a shadow will be added to the button
   * @default false
   */
  shadow?: boolean;
  /**
   * An additional info that can be shown in the button
   */
  sideInfo?: string;
  /**
   * The position at which the sideInfo will be added to the button
   * @default 'after'
   */
  sideInfoPosition?: 'before' | 'after';
  /**
   * The size of the button
   * @default 'medium'
   */
  size?: 'tiny' | 'small' | 'medium' | 'large';
  /**
   * The style used for the loading indicator
   * @default 'secondary'
   */
  spinnerVariant?: 'primary' | 'secondary' | 'tertiary';
  /**
   * The type of the button
   * @default 'button'
   */
  type?: 'button' | 'reset' | 'submit';
  /**
   * The style used for the button
   * @default 'primary'
   */
  variant?: 'orange' | 'primary' | 'secondary' | 'tertiary';
  /**
   * Classname(s) applied to the button
   * @default ''
   */
  className?: string;
  /**
   * The content of the button
   */
  children: ReactNode;
} & (
  | Omit<HTMLProps<HTMLAnchorElement>, 'size'>
  | Omit<HTMLProps<HTMLButtonElement>, 'size'>
);

const Button = forwardRef<HTMLAnchorElement | HTMLButtonElement, Props>(
  (
    {
      disabled,
      disabledStyle = 'faded',
      fullWidth,
      ghost,
      icon,
      iconPosition = 'after',
      isLink,
      loading,
      loaderOnly,
      shadow = true,
      sideInfo,
      sideInfoPosition = 'after',
      size = 'medium',
      spinnerVariant = 'secondary',
      type = 'button',
      variant = 'primary',
      className,
      children,
      ...restProps
    },
    ref,
  ): JSX.Element => {
    const ButtonTag = isLink ? 'a' : 'button';
    const btnConfig = {
      variant: {
        orange: `btn-orange`,
        primary: `btn-primary`,
        secondary: `btn-secondary`,
        tertiary: `btn-tertiary`,
      },
      ghost: {
        orange: `btn-orange-ghost`,
        primary: `btn-primary-ghost`,
        secondary: `btn-secondary-ghost`,
        tertiary: `btn-tertiary-ghost`,
      },
      size: {
        tiny: 'btn-tiny',
        small: 'btn-small',
        medium: 'btn-medium',
        large: 'btn-large',
      },
    };

    return (
      <ButtonTag
        // eslint-disable-next-line
        // @ts-ignore
        ref={ref}
        className={clsx(
          styles.btn,
          styles[btnConfig.variant[variant]],
          ghost && styles[btnConfig.ghost[variant]],
          disabled && [
            disabledStyle === 'faded'
              ? styles['btn-disabled-faded']
              : styles['btn-disabled-greyed'],
          ],
          isLink && styles['btn-link'],
          styles[btnConfig.size[size]],
          !!fullWidth && [
            fullWidth === 'mobile'
              ? styles['btn-full-mobile']
              : styles['btn-full'],
          ],
          shadow && styles['btn-shadow'],
          !!sideInfo && [
            sideInfoPosition === 'before'
              ? styles['btn-side-before']
              : styles['btn-side-after'],
          ],
          className,
        )}
        type={type}
        disabled={disabled}
        {...restProps}
      >
        <span
          className={clsx(
            styles['btn-inner'],
            (loading || icon) && [
              iconPosition === 'before'
                ? styles['btn-icon-before']
                : styles['btn-icon-after'],
            ],
          )}
        >
          <>
            <span className={styles['btn-content']}>
              {loading && loaderOnly ? (
                <Spinner variant={spinnerVariant} />
              ) : (
                children
              )}
            </span>
            {(() => {
              if (loading && !loaderOnly) {
                return <Spinner variant={spinnerVariant} />;
              }

              if (icon && !loading && typeof icon === 'string') {
                return <Icon type={icon} />;
              }

              return icon;
            })()}
          </>
        </span>
        {sideInfo && <span className={styles['btn-side']}>{sideInfo}</span>}
      </ButtonTag>
    );
  },
);

Button.displayName = 'Button';
export default Button;
