import { useSearchParams } from 'next/navigation';

type UseTemplateWords = () => {
  replaceTemplateWords: (content: string) => string | undefined;
};

// TEMPLATE WORD TEMPLATE. { variable | default }
export const useTemplateWords: UseTemplateWords = () => {
  const searchParams = useSearchParams();

  // eslint-disable-next-line security/detect-unsafe-regex
  const templateRegex = new RegExp(
    // eslint-disable-next-line security/detect-unsafe-regex
    /\{(?<k>[a-z]+.*?)(?:\|(?<v>[a-z].*?))?\}/,
    'gmi',
  );

  const replaceTemplateWords = (content: string): string | undefined => {
    if (!content) {
      return;
    }

    const words = [...content.matchAll(templateRegex)];
    if (!words.length) {
      return content;
    }

    let newContent = words[0].input;
    for (const word of words) {
      const key = word.groups?.k;
      const value = word.groups?.v;
      const replacement = searchParams?.get(key!) || value || key;

      let replacementRegex = `\\{${word[1]}\\}`;
      if (value) {
        replacementRegex = `\\{${word[1]}\\|${word[2]}\\}`;
      }

      const regex = new RegExp(replacementRegex, 'gmi');
      newContent = newContent?.replace(regex, replacement!);
    }

    return newContent;
  };

  return {
    replaceTemplateWords,
  };
};
