const conversionBarOpenClass = 'conversion-bar-open';

export const hideConversionBarUntilScroll = (): void => {
  const conversionBarContainer = getConversionBarContainer();
  if (
    !conversionBarContainer ||
    !isConversionBarVisible(conversionBarContainer)
  ) {
    return;
  }

  // hide the conversion bar
  toggleConversionBarVisibility(conversionBarContainer, false);

  const currentYOffset = window.pageYOffset;
  const handleScroll = (): void => {
    const removeHandler = restoreConversionBar(currentYOffset);
    if (removeHandler) {
      window.removeEventListener('scroll', handleScroll);
    }
  };

  window.addEventListener('scroll', handleScroll, {
    passive: true,
  });
};

const getConversionBarContainer = (): Element | null => {
  return document.querySelector('.conversion-bar-container');
};

const isConversionBarVisible = (conversionBarContainer: Element): boolean => {
  return conversionBarContainer.classList.contains(conversionBarOpenClass);
};

const toggleConversionBarVisibility = (
  conversionBarContainer: Element,
  force?: boolean,
): void => {
  conversionBarContainer.classList.toggle(conversionBarOpenClass, force);
};

const restoreConversionBar = (currentYOffset: number): boolean => {
  const conversionBarContainer = getConversionBarContainer();
  if (
    !conversionBarContainer ||
    isConversionBarVisible(conversionBarContainer)
  ) {
    return true;
  }

  const yPageOffset = 300;
  if (window.pageYOffset >= currentYOffset + yPageOffset) {
    // show the conversion bar
    toggleConversionBarVisibility(conversionBarContainer, true);
    return true;
  }

  return false;
};
