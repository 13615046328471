import clsx from 'clsx';
import Link from '@quno/patient-journey/src/components/ui/Link';
import { QUNOMEDICAL_DOMAIN } from '@quno/patient-journey/src/constants/brand';
import { useTemplateWords } from '@quno/patient-journey/src/hooks/useTemplateWords';
import styles from './RichText.module.scss';
import type { Document } from '@contentful/rich-text-types';
import type { JsonRichText } from '@quno/patient-journey/types/Utilities';

export type RichTextConfig = {
  'heading-1'?: string;
  'heading-2'?: string;
  'heading-3'?: string;
  'heading-4'?: string;
  'heading-5'?: string;
  'heading-6'?: string;
  paragraph?: string;
  'unordered-list'?: string;
  'ordered-list'?: string;
  'list-item'?: string;
  hyperlink?: string;
  blockquote?: string;
  hr?: string;
  bold?: string;
};

type Fields = Document | JsonRichText;

type Props = {
  fields?: Fields;
  spacing?: boolean;
  config?: RichTextConfig | null;
  isQunomedical?: boolean;
  noWrapper?: boolean;
  customStyle?: string;
  style?: React.CSSProperties;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getNode = (fields: any): any => {
  let node = fields;

  if (fields.json) {
    node = fields.json;
    if (!node.data && node.content) {
      node = node.content;
    }
  }

  return node;
};

const getConfig = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fields: any,
  _config: RichTextConfig | null,
): RichTextConfig | null => {
  let config = _config || null;

  if (fields.json?.config) {
    ({ config } = fields.json);
  }

  return config;
};

const RichText = ({
  fields,
  spacing,
  config: _config = null,
  isQunomedical,
  noWrapper,
  customStyle,
  style,
}: Props): JSX.Element | null => {
  const { replaceTemplateWords } = useTemplateWords();

  if (!fields) {
    return null;
  }

  const node = getNode(fields);
  const config = getConfig(fields, _config);

  const getClasses = (
    defaultClasses: string,
    key: keyof RichTextConfig,
  ): string => {
    return config?.[key] || defaultClasses;
  };

  if (
    node.content?.length ||
    node.nodeType === 'hr' ||
    node.nodeType === 'paragraph'
  ) {
    switch (node.nodeType) {
      case 'document':
        if (noWrapper) {
          return node.content.map((fields: Fields, i: number) => (
            <RichText
              key={i}
              fields={fields}
              spacing={spacing}
              config={config}
              isQunomedical={isQunomedical}
              style={style}
            />
          ));
        }

        return (
          <div
            className={clsx(
              spacing && 'max-w-screen-xl mx-auto mb-6 px-5 md:px-4',
              customStyle,
            )}
            style={style}
          >
            {node.content.map((fields: Fields, i: number) => (
              <RichText
                key={i}
                fields={fields}
                spacing={spacing}
                config={config}
                isQunomedical={isQunomedical}
                style={style}
              />
            ))}
          </div>
        );

      case 'heading-1':
        return (
          <h1
            className={getClasses('', 'heading-1')}
            style={{ ...style, paddingTop: '1rem' }}
          >
            {node.content.map((fields: Fields, i: number) => (
              <RichText
                key={i}
                fields={fields}
                spacing={spacing}
                config={config}
                isQunomedical={isQunomedical}
                style={style}
              />
            ))}
          </h1>
        );

      case 'heading-2': {
        const content = node.content[1];

        return (
          <h2
            className={getClasses('', 'heading-2')}
            id={
              content?.nodeType === 'hyperlink'
                ? content.data.uri.replace('#', '') || ''
                : ''
            }
            style={{ ...style, paddingTop: '1rem' }}
          >
            {node.content.map((fields: Fields, i: number) => (
              <RichText
                key={i}
                fields={fields}
                spacing={spacing}
                config={config}
                isQunomedical={isQunomedical}
                style={style}
              />
            ))}
          </h2>
        );
      }

      case 'heading-3':
        return (
          <h3
            className={getClasses('', 'heading-3')}
            style={{ ...style, paddingTop: '1rem' }}
          >
            {node.content.map((fields: Fields, i: number) => (
              <RichText
                key={i}
                fields={fields}
                spacing={spacing}
                config={config}
                isQunomedical={isQunomedical}
                style={style}
              />
            ))}
          </h3>
        );

      case 'heading-4':
        return (
          <h4
            className={getClasses('', 'heading-4')}
            style={{ ...style, paddingTop: '1rem' }}
          >
            {node.content.map((fields: Fields, i: number) => (
              <RichText
                key={i}
                fields={fields}
                spacing={spacing}
                config={config}
                isQunomedical={isQunomedical}
                style={style}
              />
            ))}
          </h4>
        );

      case 'heading-5':
        return (
          <h5
            className={getClasses('', 'heading-5')}
            style={{ ...style, paddingTop: '1rem' }}
          >
            {node.content.map((fields: Fields, i: number) => (
              <RichText
                key={i}
                fields={fields}
                spacing={spacing}
                config={config}
                isQunomedical={isQunomedical}
                style={style}
              />
            ))}
          </h5>
        );

      case 'heading-6':
        return (
          <h6
            className={getClasses('md:mb-1 text-xl', 'heading-6')}
            style={{ ...style, paddingTop: '1rem' }}
          >
            {node.content.map((fields: Fields, i: number) => (
              <RichText
                key={i}
                fields={fields}
                spacing={spacing}
                config={config}
                isQunomedical={isQunomedical}
                style={style}
              />
            ))}
          </h6>
        );

      case 'paragraph': {
        return (
          <p
            className={clsx(
              getClasses(
                'pb-6 last:pb-0 whitespace-pre-line leading-normal',
                'paragraph',
              ),
              styles.paragraph,
            )}
            style={{ ...style, paddingTop: '1rem' }}
          >
            {node.content.map((fields: Fields, i: number) => (
              <RichText
                key={i}
                fields={fields}
                spacing={spacing}
                config={config}
                isQunomedical={isQunomedical}
                style={style}
              />
            ))}
          </p>
        );
      }

      case 'unordered-list':
        return (
          <ul
            className={getClasses(
              clsx('list-disc pl-8 mb-6', styles.unorderedList),
              'unordered-list',
            )}
            style={style}
          >
            {node.content.map((fields: Fields, i: number) => (
              <RichText
                key={i}
                fields={fields}
                spacing={spacing}
                config={config}
                isQunomedical={isQunomedical}
                style={style}
              />
            ))}
          </ul>
        );

      case 'ordered-list':
        return (
          <ol
            className={getClasses('list-decimal pl-4 mb-6', 'ordered-list')}
            style={style}
          >
            {node.content.map((fields: Fields, i: number) => (
              <RichText
                key={i}
                fields={fields}
                spacing={spacing}
                config={config}
                isQunomedical={isQunomedical}
                style={style}
              />
            ))}
          </ol>
        );

      case 'list-item':
        return (
          <li
            className={getClasses('mb-3 list-none', 'list-item')}
            style={style}
          >
            {node.content.map((fields: Fields, i: number) => (
              <RichText
                key={i}
                fields={fields}
                spacing={spacing}
                config={config}
                isQunomedical={isQunomedical}
                style={style}
              />
            ))}
          </li>
        );

      case 'hyperlink':
        if (!isQunomedical && node.data.uri?.includes(QUNOMEDICAL_DOMAIN)) {
          return node.content.map((fields: Fields, i: number) => (
            <RichText
              key={i}
              fields={fields}
              spacing={spacing}
              config={config}
              isQunomedical={isQunomedical}
              style={style}
            />
          ));
        }

        return (
          <Link href={node.data.uri || ''} prefetch={false}>
            <a
              className={getClasses(
                'text-navy underline break-words',
                'hyperlink',
              )}
              style={style}
            >
              {node.content.map((fields: Fields, i: number) => (
                <RichText
                  key={i}
                  fields={fields}
                  spacing={spacing}
                  config={config}
                  isQunomedical={isQunomedical}
                  style={style}
                />
              ))}
            </a>
          </Link>
        );

      case 'blockquote':
        return (
          <blockquote
            className={getClasses(
              'py-4 pl-4 mb-4 border-l-8 border-gray-400',
              'blockquote',
            )}
          >
            {node.content.map((fields: Fields, i: number) => (
              <RichText
                key={i}
                fields={fields}
                spacing={spacing}
                config={config}
                isQunomedical={isQunomedical}
                style={style}
              />
            ))}
          </blockquote>
        );

      case 'hr':
        return <hr className={getClasses('my-6 border-silver-light', 'hr')} />;

      default:
        return null;
    }
  }

  if (node.nodeType === 'text' && node.marks) {
    if (node.marks.length) {
      const classes: string[] = [];

      for (const mark of node.marks) {
        switch (mark.type) {
          case 'bold':
            classes.push(...['font-bold', config?.bold || styles.bold]);
            break;
          case 'italic':
            classes.push('italic');
            break;
          case 'underline':
            classes.push('underline');
            break;
          default:
            break;
        }
      }

      const className = classes.filter(Boolean).join(' ');
      return (
        <span className={className} style={style}>
          {replaceTemplateWords(node.value)}
        </span>
      );
    }

    return <>{replaceTemplateWords(node.value) || ''}</>;
  }

  return null;
};

export default RichText;
