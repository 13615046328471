export const CLOUDINARY_UPLOAD_URL =
  'https://res.cloudinary.com/junomedical/image/upload';

export const LINK_SHARE_IMAGE = `${CLOUDINARY_UPLOAD_URL}/link-share-image-new.png`;

export const UPPY_MAX_FILE_SIZE_BYTES = 15000000;

export enum IMGS_PATH {
  CONTENTFUL = 'https://images.ctfassets.net/kfkw517g6gvn',
  PUBLIC = '/v2/images',
}

export const CARD_IMAGE_MAX_WIDTH = 320;
export const BLUR_IMAGE_WIDTH = 320;
